import { FC, ReactNode } from "react";
import styled from "styled-components";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";

const PageBox = styled.div`
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const PageContent = styled.div`
  flex: 1;
`;

const PageFooter = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
`;

const AppPageShell: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <PageBox>
      <Header />
      <PageContent>{children}</PageContent>
      <PageFooter>
        <Footer />
      </PageFooter>
    </PageBox>
  );
};

export default AppPageShell;
