import { useState, useContext, useEffect } from "react";
import {
  Title,
  PageTitle,
  SecondaryText,
  RegularText,
} from "../atoms/Typography";
import Panel from "../atoms/layout/Panel";
import StyledLine from "../atoms/shapes/StyledLine";
import PassAvatar from "../components/pass/PassAvatar";
import Button from "../atoms/inputs/Button";
import CheckBox from "../atoms/inputs/CheckBox";
import { ContactContext } from "../contexts/ContactContext";
import ShoppingForm from "../components/shoppingForm/ShoppingForm";
import { GradientRoundedContainer } from "../atoms/layout/Boxes";
import { PaymentS } from "../services/PaymentS";
import { WindowContext } from "../contexts/WindowContext";
import { Contact } from "../services/AuthS";
import { Container, Skeleton } from "@mui/material";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";
import { useSearchParams } from "react-router-dom";
import { PassPlan, PassS } from "../services/PassS";
import { FormS } from "../services/FormS";
import Header from "../components/layout/Header";
import { LocalStorageS } from "../services/LocalStorageS";

const ShoppingCartPage = () => {
  const { contact, updateContact } = useContext(ContactContext);
  const [searchParams] = useSearchParams();
  const [localContact, updateLocalContact] = useState<Contact>(contact);
  const [loading, setLoading] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { isPhoneDevice, isDesktopDevice } = useContext(WindowContext);
  const [passPlan, setPlan] = useState<PassPlan | undefined>();

  useEffect(() => {
    const passLabel: string | null = searchParams.get("pass");
    if (passLabel) {
      setPlan(PassS.getByLabel(passLabel));
    }
    LocalStorageS.remove("startDate");
  }, []);

  useEffect(() => {
    if (contact) {
      updateLocalContact(FormS.replaceNullValues(contact));
    }
  }, [contact]);

  if (!passPlan) {
    return null;
  }

  const updateContactField = (key: string, value: any) => {
    updateLocalContact({
      ...localContact,
      [key]: value,
    });
  };

  const finishOrder = () => {
    updateContact(localContact);
    // Lets wait some time so the contact changes are update in PaymentS
    setTimeout(createOrder, 500);
  };

  const createOrder = async () => {
    setLoading(true);
    const hostedPage = await PaymentS.createCheckout(
      passPlan,
      undefined,
      localContact.startDate
    );
    if (hostedPage) {
      window.location.href = hostedPage.url;
    }
  };

  return (
    <>
      <BackdropLoader isOpen={loading} />
      <Header />
      <GradientRoundedContainer rounded={"bottom"}>
        <PageTitle small={isPhoneDevice}>WARENKORB</PageTitle>
        <PassAvatar pass={passPlan} size={isPhoneDevice ? "small" : "medium"} />
        <Panel>
          <Title large={isDesktopDevice}>
            {`${passPlan.longName} ${passPlan.desc} Pass`}
          </Title>
          <RegularText large={isDesktopDevice}>
            <span style={{ fontWeight: "bold" }}> {passPlan.days} Tage </span>{" "}
            Gültigkeit
          </RegularText>
          <RegularText large={isDesktopDevice}>
            Keine automatische Verlängerung
          </RegularText>
        </Panel>
        <StyledLine color={"#212121"} width={"70%"} maxWidth={"600px"} />
        <Panel>
          <Title large={isDesktopDevice}>
            {Number(passPlan.price).toFixed(2)} Euro
          </Title>
          <SecondaryText>(inkl MwSt.)</SecondaryText>
        </Panel>
      </GradientRoundedContainer>
      {localContact ? (
        <Container>
          <ShoppingForm
            contact={localContact}
            updateField={updateContactField}
          />
        </Container>
      ) : (
        <Skeleton
          variant="text"
          width={"70%"}
          height={350}
          style={{ margin: "auto" }}
        />
      )}
      <GradientRoundedContainer>
        <Panel>
          <div
            style={{ width: "70%", maxWidth: 450, margin: "0 auto 2rem auto" }}
          >
            <CheckBox
              checked={termsAccepted}
              setChecked={setTermsAccepted}
              text={
                <>
                  Hiermit bestätige ich, die
                  <a target={"_blank"} href={"/legal/agb.pdf"}>
                    {" "}
                    <u>AGB</u>{" "}
                  </a>
                  und
                  <a target={"_blank"} href={"/data-privacy"}>
                    {" "}
                    <u>Datenschrutzerklärung</u>{" "}
                  </a>
                  zur Kenntnis genommen zu haben.
                </>
              }
              errorMessage={"Sie müssen die Vertragslaufzeit akzeptieren"}
            />
          </div>
        </Panel>
        <Panel>
          <Button
            label={"ZAHLUNGPFLICHTIG KAUFEN"}
            onClick={finishOrder}
            disabled={!termsAccepted}
            maxWidth={"300px"}
          />
        </Panel>
        <br />
      </GradientRoundedContainer>
    </>
  );
};

export default ShoppingCartPage;
