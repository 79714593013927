import moment from "moment";
import { Deal, WeekDays } from "./DealS";

const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;
const _day = _hour * 24;

const DAYS = [
  {
    name: "monday",
    label: "MO",
  },
  {
    name: "tuesday",
    label: "DI",
  },
  {
    name: "wednesday",
    label: "MI",
  },
  {
    name: "thursday",
    label: "DO",
  },
  {
    name: "friday",
    label: "FR",
  },
  {
    name: "saturday",
    label: "SA",
  },
  {
    name: "sunday",
    label: "SO",
  },
];

const isDealAvailable = (deal: Deal | undefined): boolean => {
  if (deal) {
    const now = new Date();
    const dayInWeek = now.getDay();
    const today = DAYS[dayInWeek === 0 ? 6 : dayInWeek - 1]
      .name as keyof WeekDays;

    for (let i = 0; i < deal.timeFrames.length; i++) {
      const timeFrame = deal.timeFrames[i];
      const { startTime, endTime } = timeFrame;
      if (
        deal.timeFrames[i][today] &&
        ((!startTime && !endTime) ||
          moment(now).isBetween(
            getDateWithTime(startTime),
            getDateWithTime(endTime)
          ))
      ) {
        return true;
      }
    }
  }
  return false;
};

const getDateWithTime = (timeString: string) => {
  // Split the input time string "HH:mm:ss" into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Get today's date
  const today = new Date();

  // Set the hours, minutes, and seconds for the current date
  today.setHours(hours, minutes, seconds, 0); // The last '0' sets milliseconds to 0

  return today;
};

const hourToDate = (hourStr: string) => {
  if (!hourStr) {
    return undefined;
  }
  const today = new Date();
  const range = hourStr.length === 5 ? 2 : 1;

  today.setHours(parseInt(hourStr.substring(0, range)));
  today.setMinutes(0);

  return today;
};

const getDefaultTimeFilter = () => {
  const now = new Date();
  const nowPlusTwoHours = addHours();
  const today = DAYS[now.getDay() - 1].name;

  return {
    startTime: moment(now).format("HH:mm"),
    endTime: moment(nowPlusTwoHours).format("HH:mm"),
    [today]: true,
  };
};

const addHours = (numOfHours = 2, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * _hour);
  return date;
};

const plusHours = (hourStr: string, plusHour: number) => {
  if (!hourStr) {
    return undefined;
  }
  const range = hourStr.length === 5 ? 2 : 1;
  const hour = parseInt(hourStr.substring(0, range));

  return hour + plusHour + ":00";
};

const todayDate = (format: string = "yyyy-MM-DD") => {
  return moment(new Date()).format(format);
};

const getPassCountdown = (validTill: any) => {
  const expirationDate = new Date(validTill);
  const now = new Date();
  const difference = expirationDate.getTime() - now.getTime();

  var days = Math.floor(difference / _day);
  var hours = Math.floor((difference % _day) / _hour);
  var minutes = Math.floor((difference % _hour) / _minute);

  return "Noch " + days + " Tage " + hours + " h " + minutes + " min gültig";
};

const getTicketCountdown = (validTill: any) => {
  const expirationDate = new Date(validTill);
  const now = new Date();
  const difference = expirationDate.getTime() - now.getTime();

  var minutes = Math.floor((difference % _hour) / _minute);
  var seconds = Math.floor((difference % _minute) / _second);

  return { minutes, seconds };
};

const formatDate = (p: any, format: string = "DD.MM.yyyy") => {
  if (p) {
    const d = new Date(p);
    return moment(d).format(format);
  }
};

export const DateS = {
  DAYS,
  hourToDate,
  plusHours,
  todayDate,
  getPassCountdown,
  formatDate,
  getDefaultTimeFilter,
  getTicketCountdown,
  isDealAvailable,
};
