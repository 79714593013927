const MENU_OPTIONS = [
  {
    id: 'home',
    href: '/',
    label: 'Home'
  },
  {
    id: 'deals',
    href: '/deals',
    label: 'Dealübersicht'
  },
  {
    id: 'purchase-pass',
    href: '/purchase-pass',
    label: 'Pass kaufen'
  },
  {
    id: 'faq',
    href: '/faq',
    label: 'FAQ'
  },
  {
    id: 'become-partner',
    href: 'https://partner.deinfreundedeal.de/register',
    label: 'Partner werden',
    external: true       
  }
];


const PROTECTED_ROUTES = [
  '/change-password', 
  '/shopping-cart', 
  '/passport'
];

const UNPROTECTED_ROUTES = [
  '/login', 
  '/register', 
  '/password-reset',
  '/confirm-registration',
  '/password-forgotten',
  '/confirm-mail'
];

const isNonAuthRoute = (routePath: string) => {
  return UNPROTECTED_ROUTES.includes(routePath);
}

const isRouteProtected = (routePath: string) => {
  return PROTECTED_ROUTES.includes(routePath);
}

const openInNewTab = (link: string) => window.open(link, '_blank', 'noopener,noreferrer');

export const RoutingS = {
  MENU_OPTIONS,
  isRouteProtected,
  isNonAuthRoute,
  openInNewTab
}