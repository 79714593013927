import React, { useContext } from "react";
import Panel from "../atoms/layout/Panel";
import { PageTitle, RegularText } from "../atoms/Typography";
import { WindowContext } from "../contexts/WindowContext";
import AppPageShell from "./AppPageShell";

const ImpressumPage: React.FC = () => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <AppPageShell>
      <div>
        <Panel>
          <PageTitle small={isPhoneDevice}>Impressum</PageTitle>
        </Panel>
        <Panel>
          <RegularText>
            Dein Freunde Deal UG (haftungsbeschränkt) i.G.
          </RegularText>
          <RegularText>Carl-August-Allee 1</RegularText>
          <RegularText>99423 Weimar</RegularText>
          <RegularText>Germany</RegularText>
        </Panel>
        <Panel>
          <RegularText>www.deinfreundedeal.de</RegularText>
          <RegularText>E-Mail: info@deinfreundedeal.de</RegularText>
          <RegularText>Telefon: (+49) 0170 2626853</RegularText>
        </Panel>
        <Panel>
          <RegularText>USt.ID: DE321147364</RegularText>
          <RegularText>Vertretungsberechtigt: Georg Ronny Müller</RegularText>
        </Panel>
        <Panel>
          <RegularText>Amtsgericht Jena</RegularText>
          <RegularText>HRB 518528</RegularText>
        </Panel>
      </div>
    </AppPageShell>
  );
};

export default ImpressumPage;
